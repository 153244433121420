import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isPlainObject from 'lodash/isPlainObject';
import getKey from 'lodash/get';
import debounce from 'lodash/debounce';

import moment from 'moment-timezone';

import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  createRef,
  cloneElement,
} from 'react';
import {
  stringify as stringifyQuery,
} from 'querystring';
import {
  // useHistory,
  Redirect,
} from 'react-router-dom';

import ContextProviderComponent from './ContextProviderComponent';

import Types from '../modules/types';

import { useLocation } from './Location';
import { useApi, useApiRequest } from './Api';
import { useStore, useStoreCurrency } from './Store';
import { useViewer } from './Viewer';
import { useAnalytics } from './Analytics';
import { useDictionary } from './Language';

import { getPriceWithCurrency } from '../helpers/PriceWithCurrency';

const {
  CONSTANTS: {
    ShippingMethod,
  },
} = Types;

const {
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_CHECKOUT_V2_PUBLIC_KEY_UAE,
  REACT_APP_CHECKOUT_V2_PUBLIC_KEY_INTL,
} = process.env;

const SERVER_URL = `${REACT_APP_SERVER_PROTOCOL
  }://${REACT_APP_SERVER_HOST
  }${REACT_APP_HTTP_PORT === '80' ? '' : `:${REACT_APP_HTTP_PORT}`
  }`;

const Context = createContext();

export const KEY_CART_OPEN = 'CART_OPEN';
export const KEY_CART_ITEMS = 'CART_ITEMS';
export const KEY_SUBMIT_FIELDS = 'SUBMIT_FIELDS';

export const getCartItemFromProductAndVariation = (
  product,
  variation,
  quantity = 1,
  options = {},
) => {
  const optionsMap = (
    isPlainObject(options)
      ? options
      : Array.isArray(options)
        ? options.reduce(
          (agr, option) => {
            agr[option._id] = option.value;
            return agr;
          },
          {},
        )
        : {}
  );
  return {
    sku: variation.sku,
    price: variation.price,
    meta: {
      name: product.name,
      kind: product.kind || Types.CONSTANTS.ProductKind.TANGIBLE,
      tagline: product.tagline,
      productSlug: product.slug,
      storeSku: variation.storeSku,
      variationSlug: variation.slug,
      variation: variation.name,
      image: (variation.image || product.image)?.src,
      options: optionsMap,
      // TODO Missing categoryName
    },
    quantity,
  };
};

export const getSubmitFieldsFromSavedData = (store) => {
  const savedCustomerDetails = store.getData(KEY_SUBMIT_FIELDS);
  if (savedCustomerDetails) {
    return {
      ...savedCustomerDetails,
      ...(store.data?.customer || {}),
    };
  }
  return null;
};

export const getSubmitFieldsFromViewer = (viewer) => {
  if (viewer?.data?.store?.active !== true) {
    return {};
  }
  viewer = viewer.data;
  try {
    return {
      store: viewer.store._id,
      country: viewer.systemCountry,
      firstName: viewer.name.split(/\s+/)[0],
      lastName: viewer.name.split(/\s+/)[1],
      email: viewer.email,
      phoneNumberString: viewer.phoneNumberString,
      // "shipping": "ABSTRACT",
      city: viewer.store.address.city,
      postalCode: viewer.store.address.postalCode,
      coordinatesString: viewer.store.address.coordinatesString,
      line1: viewer.store.address.line1,
      line2: viewer.store.address.line2,
      payment: 'CARD',
      // billing: {
      //   firstName: null,
      //   lastName: null,
      //   email: null,
      //   phoneNumber: null,
      //   country: null,
      //   city: null,
      //   postalCode: null,
      //   line1: null,
      // },
    };
  } catch (error) {
    return {};
  }
};

// eslint-disable-next-line arrow-body-style
export const getSubmitFieldsForPosCheckout = (store) => {
  return {
    country: store.systemCountry,
    // email: 'pos-customer@packman.app',
    phoneNumberString: '+9715555555',
    city: store.address.city,
    postalCode: store.address.postalCode,
    coordinatesString: store.address.coordinatesString,
    line1: store.address.line1,
    line2: store.address.line2,
    payment: 'CARD',
    shipping: 'POS',
  };
};

export const getCartItemsFromSavedData = async (cartItems, store, api) => {
  if (cartItems && cartItems.length) {
    try {
      const products = await api.get('products', {
        where: {
          store: store.data._id,
          'variations.sku': { IN: cartItems.map(({ sku }) => sku) },
        },
        limit: 100,
      });
      if (products.length) {
        const items = [];
        cartItems.forEach(({ sku, quantity, meta, options, comment }) => {
          for (let p = 0; p < products.length; p++) {
            const product = products[p];
            for (let v = 0; v < product.variations.length; v++) {
              const variation = product.variations[v];
              if (variation.sku === sku) {
                items.push({
                  ...getCartItemFromProductAndVariation(
                    product,
                    variation,
                    quantity || 1,
                    options || meta.options || [],
                  ),
                  comment,
                });
              }
            }
          }
        });
        if (items.length) {
          return items;
        }
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

export default Context;

export const { Consumer } = Context;

export function RiskProvider({ children }) {
  const { data: store } = useStore();
  const {
    checkoutComChannel,
    paymentCardProvider: paymentCardProviderDefault,
  } = Types.getSystemCountry(store.systemCountry);
  const publicKey = (
    checkoutComChannel === 'uae'
      ? REACT_APP_CHECKOUT_V2_PUBLIC_KEY_UAE
      : REACT_APP_CHECKOUT_V2_PUBLIC_KEY_INTL
  );
  const isCheckout = (
    store.paymentCardProvider
    || paymentCardProviderDefault
  ) === 'CHECKOUT';
  const [risk, setRisk] = useState(null);
  useEffect(
    () => {
      if (isCheckout) {
        let interval = null;
        if (!window.Risk) {
          interval = setInterval(() => {
            if (window.Risk) {
              setRisk(window.Risk.init(publicKey));
              clearInterval(interval);
            }
          }, 500);
          return () => {
            clearInterval(interval);
          }
        }
        setRisk(window.Risk.init(publicKey));
      }
      return undefined;
    },
    [risk, publicKey, isCheckout],
  );

  return (
    !isCheckout
      ? children
      : risk
        ? cloneElement(children, { risk })
        : null
  );
}

export function Provider({ children, ...props }) {
  const api = useApi();
  const store = useStore();
  const analytics = useAnalytics();
  const { pathname, searchParams } = useLocation();
  // const history = useHistory();
  const submitFields = useMemo(
    () => {
      try {
        return JSON.parse(searchParams.fields);
      } catch (error) {
        return null;
      }
    },
    [searchParams.fields],
  );
  const viewer = useViewer();
  const posSkip = !props.posOrderId || !props.posOrderId.length;
  const [{ data: posOrder, loading: posLoading }] = useApiRequest({
    url: `orders/${props.posOrderId}?${stringifyQuery({
      query: JSON.stringify({
        where: { status: 'DRAFT' },
      }),
    })}`,
    skip: posSkip,
  });
  if (posLoading || viewer.loading || store.loading || !store.data) {
    return null;
  }
  if (
    (!posSkip && !posLoading)
    && (!posOrder || posOrder.store !== store.data._id)
  ) {
    const newSearchParams = { ...searchParams };
    delete newSearchParams.pos;
    const searchString = stringifyQuery(newSearchParams);
    return (
      <Redirect
        to={`${pathname}${searchString ? `?${searchString}` : ''}`}
      />
    );
  }
  return (
    <RiskProvider>
      <CartProvider
        key={store._id}
        api={api}
        store={store}
        viewer={viewer}
        analytics={analytics}
        submitFields={submitFields}
        discountCode={searchParams.discountcode}
        posOrder={posOrder}
        {...props}
      >
        {children}
      </CartProvider>
    </RiskProvider>
  );
}

export class CartProvider extends ContextProviderComponent {
  static NAME = 'CART'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  constructor(props) {
    super(props);
    global.CART = this;
    this.checkoutCardInputRef = createRef();
    this.stripeCardRef = createRef();
    this.stripeCardRef.current = {};
    this.exposeMethods(
      'onFormInitialize',
      'onFormChange',
      'toggleShowCart',
      'setItemComment',
      'addToCart',
      'removeFromCart',
      'clearCart',
      'submit',
      'paymobSubmit',
      'setError',
      'getStripePaymentIntent',
      'reEvaluate',
      'redirectToThankyouPage',
      'cancelPreviousOrder',
      'redirectToApplePayPage',
    );
    Object.assign(this.state, {
      data: null,
      order: null,
      loading: true,
      submitting: false,
      evaluating: true,
      error: null,
      isOpen: false,
      items: [],
      countries: [],
      shipping: {},
      scheduling: false,
      payment: {},
      paymob: {},
      paymobOrderId: null,
      stock: null,
      checkoutCardInputRef: this.checkoutCardInputRef,
      stripeCardRef: this.stripeCardRef,
      stripePaymentIntent: {},
      posOrderId: props.posOrderId,
      posOrder: props.posOrder,
    });
    Object.assign(
      this.state,
      this.getCalculatedState(
        this.props,
        {
          defaultSubmitFields: (
            props.store?.data?.customer
              ? props.store?.data?.customer
              : props.viewer
                ? getSubmitFieldsFromViewer(props.viewer)
                : {}
          ),
        }
      ),
    );

    this.evaluateDebounced = debounce(
      this.evaluate,
      2000,
      // { leading: true },
    );

    this.evaluateDebounced.now = (...args) => {
      this.evaluateDebounced.cancel();
      // this.evaluateDebounced(...args);
      this.evaluate(...args);
    };

    this.saveSubmitFieldsDebounced = debounce(
      this.saveSubmitFields,
      200,
    );

    this.saveItemsDebounced = debounce(
      this.saveItems,
      200,
    );
  }

  async componentDidMount() {
    const { store, api, forceInitialClosed, posOrder } = this.props;
    const savedCartItems = await getCartItemsFromSavedData(
      posOrder ? posOrder.items : store.getData(KEY_CART_ITEMS),
      store,
      api,
    ); 
    const savedSubmitFields = await getSubmitFieldsFromSavedData(store);
    // const savedCartItems = null;
    // const savedSubmitFields = null;
    const cartOpen = (
      forceInitialClosed
        ? false
        : !!store.getData(KEY_CART_OPEN)
    );
    const changes = { loading: false };
    changes.defaultIsOpen = cartOpen;
    if (savedCartItems) {
      changes.items = savedCartItems.map(item => this.getCalculatedItem(item));
    }
    if (posOrder) {
      changes.defaultSubmitFields = Object.assign(
        changes.defaultSubmitFields || {},
        getSubmitFieldsForPosCheckout(store.data),
      );
      if (posOrder.discountCode) {
        changes.defaultSubmitFields.discountCode = posOrder.discountCodeValue;
      } else {
        delete changes.defaultSubmitFields.discountCode;
      }
      this.lastCountry = changes.defaultSubmitFields.country;
      this.saveSubmitFields(changes.defaultSubmitFields);
    } else {
      if (savedSubmitFields) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          savedSubmitFields
        );
        this.lastCountry = (
          savedSubmitFields.country || changes.defaultSubmitFields.country
        );
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
      if (this.props.submitFields) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          this.props.submitFields,
        );
        if (this.props.submitFields.country) {
          this.lastCountry = this.props.submitFields.country;
        }
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
      if (this.props.store?.data?.customer) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          this.props.store.data.customer,
        );
        if (this.props.store.data.customer.country) {
          this.lastCountry = this.props.store.data.customer.country;
        }
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
    }
    if (this.props.viewer) {
      changes.defaultSubmitFields = Object.assign(
        changes.defaultSubmitFields || {},
        getSubmitFieldsFromViewer(this.props.viewer),
      );
      this.lastCountry = changes.defaultSubmitFields.country;
      this.saveSubmitFields(changes.defaultSubmitFields);
    }
    this.setCalculatedState(this.props, changes);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.submitCardTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.items !== prevState.items) {
    //   this.saveItemsDebounced(this.state.items);
    // }
    if (
      this.state.posOrderId !== this.props.posOrderId
      || this.state.posOrder !== this.props.posOrder
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        posOrderId: this.props.posOrderId,
        posOrder: this.props.posOrder,
      });
    }
    if (!prevState.isOpen && this.state.isOpen === true) {
      const { currency: systemCurrency } = (
        this.props.store?.data
          ? Types.getSystemCountry(this.props.store.data.systemCountry)
          : {}
      );
      this.props.analytics.trigger.CART_OPEN({
        items: this.state.items,
        currency: systemCurrency,
      });
    }
    if (
      this.form
      && (
        this.state.items !== prevState.items
        || this.props.store !== prevProps.store
        || this.props.posOrder !== prevProps.posOrder
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      const values = this.form.getValues();
      if (!this.lastCountry) {
        this.lastCountry = values.country;
      }
      this.evaluateDebounced.now(
        this.state.items,
        values,
        this.props,
      );
    }
  }

  getCalculatedItem({ price, quantity = 1, ...item }) {
    const priceTotal = price * quantity;
    return {
      ...item,
      price,
      quantity,
      priceTotal,
      priceTotalFormatted: Types.decimalize(priceTotal),
    };
  }

  getCalculatedState(props, changes = {}, state) {
    state = state || this.state;
    const newState = (changes.items || state.items || []).reduce(
      (agr, item) => {
        agr.priceItems += item.priceTotal;
        agr.quantityTotal += item.quantity;
        agr.itemsMap[item.sku] = item;
        return agr;
      },
      {
        ...state,
        ...changes,
        itemsMap: {},
        priceItems: 0,
        quantityTotal: 0,
      },
    );

    newState.defaultSubmitFields.shippingScheduled = (
      !!newState.defaultSubmitFields.shippingScheduled
    );
    newState.defaultSubmitFields.shippingScheduledFor = (() => {
      let value = newState.defaultSubmitFields.shippingScheduledFor;
      if (!Number.isFinite(new Date(value).getTime())) {
        value = '';
        // } else {
        //   const momentNow = moment().startOf('day');
        //   const momentScheduledFor = moment(value).startOf('day');
      }
      return value;
    })();

    if (!newState.items.length) {
      newState.isOpen = false;
      this.props.api.post('abandoned-carts/clear', {
        email: newState.defaultSubmitFields.email,
        storeId: this.props.store.data._id,
      });
    } else if (newState.defaultSubmitFields?.email?.length) {
      this.props.api.post('abandoned-carts/update', this.getOrderSubmitData(
        newState.items,
        newState.defaultSubmitFields,
        this.props,
      ));
    }

    if (newState.defaultIsOpen) {
      delete newState.defaultIsOpen;
      if (newState.items.length) {
        newState.isOpen = true;
      }
    }

    const cost = newState.cost || {};

    const values = (
      this.form
        ? this.form.getValues()
        : newState.defaultSubmitFields
    ) || {};

    newState.priceItemsDiscount = cost.itemsDiscount || 0;

    newState.priceItemsVat = cost.vat || 0;

    const freeShipping = getKey(
      newState,
      `shipping.${values.shipping}.free`,
      false,
    );

    newState.priceShipping = freeShipping
      ? 0
      : (cost.shipping || 0) - (cost.shippingVat || 0);

    newState.priceShippingVat = freeShipping
      ? 0
      : cost.shippingVat || 0;

    newState.pricePayment = (
      (cost.payment || 0) - (cost.paymentVat || 0)
    );
    newState.pricePaymentVat = cost.paymentVat || 0;

    newState.priceServiceFee = (
      (cost.serviceFee > 0 && cost.serviceFeeChargedTo === 'CUSTOMER')
        ? (cost.serviceFee || 0)
        : 0
    );
    newState.priceServiceFeeVat = (
      (cost.serviceFeeVat > 0 && cost.serviceFeeChargedTo === 'CUSTOMER')
        ? (cost.serviceFeeVat || 0)
        : 0
    );

    newState.priceVat = (
      newState.priceItemsVat
      + newState.priceShippingVat
      + newState.pricePaymentVat
      + newState.priceServiceFeeVat
    );

    newState.priceTotal = (
      newState.priceItems
      + newState.priceShipping
      + newState.pricePayment
      + newState.priceServiceFee
      + newState.priceVat
    );

    newState.priceCharged = (
      newState.priceTotal
      - newState.priceItemsDiscount
    );

    [
      'priceVat',
      'priceItems',
      'priceItemsVat',
      'priceItemsDiscount',
      'priceShipping',
      'pricePayment',
      'priceServiceFee',
      'priceTotal',
      'priceCharged',
    ].forEach((key) => {
      newState[`${key}Formatted`] = Types.decimalize(newState[key]);
    });

    return newState;
  }

  async setCalculatedState(props, changes = {}) {
    return new Promise(resolve => this.setState(
      state => this.getCalculatedState(props, changes, state),
      () => resolve(this.state),
    ));
  }

  getOrderCustomerDetails(values = {}) {
    const country = Types.COUNTRIES_MAP[values.country];
    return {
      name: values.firstName && values.lastName
        ? `${values.firstName} ${values.lastName}`
        : undefined,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumberString: values.phoneNumberString,
      address: {
        line1: values.line1,
        line2: values.line2,
        country: values.country,
        city: values.city,
        postalCode: (
          country && country.postalCode
            ? country.postalCode
            : values.postalCode
        ),
        coordinatesString: values.coordinatesString,
        district: values.district,
        area: values.area,
        neighbourhood: values.neighbourhood,
        streetName: values.streetName,
        streetNumber: values.streetNumber,
      },
    };
  }

  getOrderSubmitData(items, values, props) {
    let {
      discountCode = null,
      shippingScheduled = false,
      shippingScheduledFor,
    } = values;
    if (props.discountCode) {
      discountCode = props.discountCode;
    }
    if (discountCode && discountCode.length > 0) {
      discountCode = discountCode.toUpperCase().trim();
    }
    let { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
      this.props.store.data.systemCountry,
    );

    if (this.props.store.data.paymentCardProvider) {
      paymentCardProvider = this.props.store.data.paymentCardProvider;
    }

    if (!shippingScheduled) {
      shippingScheduled = false;
      shippingScheduledFor = undefined;
    } else {
      shippingScheduled = true;
      if (!shippingScheduledFor) {
        shippingScheduledFor = undefined;
      } else {
        const momentNow = moment();
        const momentScheduledFor = moment(shippingScheduledFor);
        momentScheduledFor.hour(momentNow.hour());
        momentScheduledFor.minute(momentNow.minute());
        momentScheduledFor.second(momentNow.second());
        shippingScheduledFor = momentScheduledFor.toJSON();
      }
    }
    return {
      store: props.store.data._id,
      language: props.store.language._id,
      comment: values.orderComment,
      customerStore: props.viewer?.data?.store?._id,
      customerStoreName: props.viewer?.data?.store?.name,
      items: items.map(({ sku, quantity, meta, comment }) => ({
        sku,
        quantity,
        comment,
        options: meta.options || [],
      })),
      payment: values.payment,
      paymob: this.state.paymob,
      paymentCardProvider,
      shipping: values.shipping,
      shippingScheduled,
      shippingScheduledFor,
      shippingDetails: this.getOrderCustomerDetails(values),
      billingDetailsDiffer: !!values.billingDetailsDiffer,
      billingDetails: (
        values.billingDetailsDiffer
          ? this.getOrderCustomerDetails(values.billing)
          : undefined
      ),
      referral: props.store.data.referral
        ? props.store.data.referral._id
        : undefined,
      discountCode,
      draftedFromPurpose: props.posOrderId ? 'POS' : undefined,
      draftedFrom: props.posOrderId || undefined,
    };
  }

  // eslint-disable-next-line no-unused-vars
  getOrderSubmitQuery(items, values, props) {
    return {};
  }

  cancelPreviousOrder = async () => this.props.api.post(
    '/orders/payment/paymob',
    {},
    {
      merchant_order_id: this.state.paymobOrderId,
      timeout: true,
    }
  );

  reEvaluate = async () => {
    this.evaluateDebounced.now(
      this.state.items || [],
      this.state.defaultSubmitFields || {},
      this.props,
    );
  }

  redirectToThankyouPage = async () => {
    await new Promise(resolve => setTimeout(resolve, 300));
    window.location.href = `/order/${this.state?.order?._id}?submitted=true`;
  }

  evaluate = async (items, values, props) => {
    if (props.store && items.length) {
      const { systemCountry: country } = props.store.data;
      let newState = null;
      const data = this.getOrderSubmitData(items, values, props);
      const aborter = this.evaluationAborter = new AbortController();
      try {
        this.setState({ evaluating: true });
        let { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
          this.props.store.data.systemCountry,
        );

        if (this.props.store.data.paymentCardProvider) {
          paymentCardProvider = this.props.store.data.paymentCardProvider;
        }

        const evaluation = await props.api.post(
          'orders/evaluate',
          data,
          undefined,
        );
        this.debug('data:', data);
        this.debug('evaluation:', evaluation);
        let ALL_SHIPPING_METHODS = [];
        let AVAILABLE_SHIPPING_METHODS = [];
        let ALL_PAYMENT_METHODS = [];
        let AVAILABLE_PAYMENT_METHODS = [];
        let shipping = null;
        // eslint-disable-next-line no-unused-vars
        let payment = null;
        newState = newState || {};
        newState.isAbstract = evaluation.isAbstract === true;
        newState.international = typeof evaluation.international === 'undefined'
          ? undefined
          : !!evaluation.international;
        newState.scheduling = !!evaluation.scheduling;
        newState.evaluating = false;
        // newState.data = data;
        newState.error = (
          (!evaluation.valid && evaluation.message)
            ? evaluation.message
            : null
        );
        newState.stock = evaluation.stock || null;
        newState.paymob = {
          paymobPublicKey: evaluation.paymobPublicKey,
          paymobClientSecret: evaluation.paymobClientSecret,
          paymobId: evaluation.paymobId,
        };
        // console.log('Paymob', JSON.stringify(newState.paymob));
        if (!evaluation.countries || !evaluation.countries.length) {
          newState = newState || {};
          newState.error = (
            newState.error
            || evaluation.error
            || 'Store offers no shipping methods'
          );
          newState.shipping = {};
          newState.payment = {};
        } else if (
          !evaluation.countries.includes(data.shippingDetails.address.country)
        ) {
          const defaultCountryIsAvailable = evaluation.countries
            .includes(country);
          const nextCountry = (
            defaultCountryIsAvailable
              ? country
              : evaluation.countries[0]
          );
          if (nextCountry !== data.shippingDetails.address.country) {
            newState.error = null;
            // this.form.setValues({
            //   ...values,
            //   country: nextCountry,
            // });
            this.form.setValue(
              'country',
              nextCountry,
              // { shouldValidate: false },
            );
          }
        } else {
          newState = newState || {};
          newState.cost = evaluation.cost || {};
          newState.countries = evaluation.countries;
          newState.shipping = evaluation.shipping;
          ALL_SHIPPING_METHODS = Object.keys(evaluation.shipping || {});
          AVAILABLE_SHIPPING_METHODS = ALL_SHIPPING_METHODS
            .filter(method => evaluation.shipping[method].available !== false);
          if (!AVAILABLE_SHIPPING_METHODS.length) {
            newState = newState || {};
            newState.error = evaluation.error || evaluation.message;
            // newState.shipping = {};
            newState.payment = {};
          } else if (!AVAILABLE_SHIPPING_METHODS.includes(data.shipping)) {
            const nextShipping = (
              (
                AVAILABLE_SHIPPING_METHODS
                  .includes(ShippingMethod.PACKMAN_SHOP_STANDARD)
              )
                ? ShippingMethod.PACKMAN_SHOP_STANDARD
                : AVAILABLE_SHIPPING_METHODS[0] || ALL_SHIPPING_METHODS[0]
            );
            if (nextShipping !== data.shipping) {
              newState.error = null;
              // this.form.setValues({
              //   ...values,
              //   shipping: nextShipping,
              // });
              this.form.setValue(
                'shipping',
                nextShipping,
                { shouldValidate: false },
              );
            }
          } else {
            shipping = evaluation.shipping[data.shipping];
            ALL_PAYMENT_METHODS = Object.keys(shipping.payment || {});
            AVAILABLE_PAYMENT_METHODS = ALL_PAYMENT_METHODS
              .filter((method) => {
                if (
                  !shipping.payment[method]?.available
                  || !isFinite(shipping.payment[method]?.price)
                ) {
                  return false;
                }
                const [
                  paymentMethodMain,
                  paymentMethodSub,
                ] = method.split('.');
                let item = Types.PAYMENT_METHOD_MAP[paymentMethodMain];
                if (paymentMethodSub && item && item.METHODS) {
                  item = item.METHODS_MAP[paymentMethodSub];
                }
                if (
                  item.checkBrowserSupport
                  && !item.checkBrowserSupport(props.store.data)
                ) {
                  delete shipping.payment[method];
                  return false;
                }
                if (
                  item.providers
                  && !item.providers.includes(paymentCardProvider)
                ) {
                  delete shipping.payment[method];
                  return false;
                }
                return true;
              });
            if (!AVAILABLE_PAYMENT_METHODS.length) {
              newState = newState || {};
              newState.error = evaluation.error || evaluation.message;
              newState.shipping = evaluation.shipping;
              newState.payment = {};
            } else if (!AVAILABLE_PAYMENT_METHODS.includes(data.payment)) {
              const nextPayment = AVAILABLE_PAYMENT_METHODS[0];
              if (nextPayment !== data.payment) {
                newState.error = null;
                this.form.setValue('payment', nextPayment);
              }
            } else if (!newState.scheduling && data.shippingScheduledFor) {
              this.form.setValue('shippingScheduled', false);
              this.form.setValue('shippingScheduledFor', '');
            } else {
              // eslint-disable-next-line no-unused-vars
              payment = shipping.payment[data.payment];
              newState = newState || {};
              newState.shipping = evaluation.shipping;
              newState.payment = shipping.payment;
              newState.error = evaluation.error || evaluation.message;
              if (
                data.discountCode
                && data.discountCode !== evaluation.discountCode
              ) {
                newState = newState || {};
                newState.error = 'Invalid discount code';
                newState.discountCodeError = true;
              } else {
                newState = newState || {};
                newState.discountCodeError = false;
              }
            }
          }
        }
      } catch (error) {
        this.debug('evaluation error:', error);
        newState = newState || {};
        newState.evaluating = false;
        newState.error = 'Oops! Something went wrong!';
        newState.countries = [];
        newState.shipping = {};
        newState.payment = {};
        if (error?.response?.status === 400) {
          newState.error = error.response.message;
        }
      }
      if (newState) {
        this.setCalculatedState(props, newState);
      }
      if (this.evaluationAborter === aborter) {
        this.setState({ evaluating: false });
      }
      this.setState({ submitting: false });
    }
  }

  saveSubmitFields = (data) => {
    if (!this.props.posOrderId) {
      this.props.store.setData(KEY_SUBMIT_FIELDS, data);
    }
  }

  saveItems = (data) => {
    if (!this.props.posOrderId) {
      this.props.store.setData(KEY_CART_ITEMS, data);
    }
  }

  onFormInitialize = (form) => {
    if (this.form !== form) {
      this.setState({ form });
    }
    this.form = form;
    if (!this.didInitialEvaluate) {
      this.didInitialEvaluate = true;
      this.evaluateDebounced.now(
        this.state.items || [],
        this.state.defaultSubmitFields || {},
        this.props,
      );
    }
  }

  onFormChange = (values, changedKeys) => {
    if (
      typeof values.country !== 'undefined'
      && this.lastCountry !== values.country
    ) {
      this.lastCountry = values.country;
      const changes = {
        city: '',
        postalCode: '',
        line1: '',
        line2: '',
        coordinatesString: '',
        district: '',
        area: '',
        neighbourhood: '',
        streetName: '',
        streetNumber: '',
      };
      values = {
        ...values,
        ...changes,
      };
      Object.keys(changes).forEach((key) => {
        this.form.setValue(key, changes[key]);
      });
    }
    this.saveSubmitFieldsDebounced(values);
    this.setState({
      // evaluating: true,
      error: null,
      defaultSubmitFields: values,
    });
    if (
      changedKeys.includes('city')
      || changedKeys.includes('line1')
      || changedKeys.includes('line2')
      || changedKeys.includes('streetName')
      || changedKeys.includes('streetNumber')
      || changedKeys.includes('area')
      || changedKeys.includes('firstName')
      || changedKeys.includes('lastName')
      || changedKeys.includes('phoneNumberString')
      || changedKeys.includes('email')
    ) {
      this.debug('form changed (debouncing):', values, changedKeys);
      this.evaluateDebounced(
        this.state.items,
        values,
        this.props,
      );
    } else if (
      changedKeys.includes('country')
      || changedKeys.includes('shipping')
      || changedKeys.includes('shippingScheduled')
      || changedKeys.includes('shippingScheduledFor')
      || changedKeys.includes('payment')
      || changedKeys.includes('coordinatesString')
      || changedKeys.includes('discountCode')
    ) {
      this.debug('form changed:', values, changedKeys);
      this.evaluateDebounced.now(
        this.state.items,
        values,
        this.props,
      );
    }
  }

  paymobSubmit = async () => {
    console.log('test')

    // try {
    //   console.log('test')
    //   const event = new Event('payFromOutside');
    //         event.eventName = 'payFromOutside';
    //         event.initEvent('payFromOutside', true);
    //         document.dispatchEvent(event);
    // } catch(err) {
    //   console.log({err});
    // }
  }
  // eslint-disable-next-line no-unused-vars
  submit = async (values, extraProps = {}) => {
    clearTimeout(this.submitCardTimeout);
    try {
      this.setState({ submitting: true });
      const data = this.getOrderSubmitData(
        this.state.items,
        values,
        this.props,
      );
      const query = this.getOrderSubmitQuery(
        this.state.items,
        values,
        this.props,
      );
      let { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
        this.props.store.data.systemCountry,
      );

      if (this.props.store.data.paymentCardProvider) {
        paymentCardProvider = this.props.store.data.paymentCardProvider;
      }

      const [payment, paymentSubmethod] = (values.payment || '').split('.');
      if (payment === 'CARD') {
        data.paymentCardProviderData = {};
        if (paymentCardProvider === 'STRIPE') {
          data.paymentCardProvider = 'STRIPE';
          data.paymentCardProviderData.stripePaymentIntent = (
            this.state.stripePaymentIntent.paymentId
          );
        } else if (paymentCardProvider === 'CHECKOUT') {
          data.paymentCardProvider = 'CHECKOUT';
          if (paymentSubmethod === 'APPLE_PAY') {
            query.precheckout = 'true';
          } else {
            const frames = await this.checkoutCardInputRef.current.getFrames();
            if (!frames.cardholder) {
              frames.cardholder = {};
            }
            const { shippingDetails } = data;
            const billingDetails = (
              data.billingDetailsDiffer
                ? data.billingDetails
                : data.shippingDetails
            );
            frames.cardholder.name = (
              billingDetails.name
              || shippingDetails.name
            );
            frames.enableSubmitForm();
            // ammar note: keep the button disabled until evaluate func 
            // this.submitCardTimeout = setTimeout(
            //   () => this.setState({ submitting: false }),
            //   2000,
            // );
            const checkoutResult = await frames.submitCard();
            clearTimeout(this.submitCardTimeout);
            // eslint-disable-next-line no-console
            // console.log(checkoutResult);
            data.paymentCardProviderData.checkoutCardData = checkoutResult;
            data.paymentCardProviderData.checkoutCardToken = (
              checkoutResult.token
            );
          }
          if (this.props.risk) {
            const checkoutRisk = await this.props.risk?.publishRiskData();
            data.paymentCardProviderData.checkoutRisk = checkoutRisk;
          }
        } else if (
          paymentCardProvider === 'PAYMOB'
          || paymentCardProvider === 'PAYMOB_PIXEL'
        ) {
          query.precheckout = 'true';
         
          data.paymentCardProviderData = {
            paymobPublicKey: data.paymob.paymobPublicKey,
            paymobClientSecret: data.paymob.paymobClientSecret,
            paymobId: data.paymob.paymobId,
          };
          clearTimeout(this.submitCardTimeout);
        } else {
          data.paymentCardProvider = 'PAYTABS';
          clearTimeout(this.submitCardTimeout);
        }
      }
      const { currency: systemCurrency } = Types.getSystemCountry(
        this.props.store.data.systemCountry,
      );
      const order = await this.props.api.post(
        'orders/submit',
        data,
        query,
      );
      this.debug('submit order:', order);
      if (order) {
        if (order.status && /^INVALID/.test(order.status)) {
          // throw new Error(order.message);
          // eslint-disable-next-line no-console
          console.log('submit invalid order error:', order.message);
          throw new Error(order.message || 'Submit failed');
        }
        // set order state
        this.state.order = order;
        if (order.payment.provider === 'STRIPE') {
          const { stripe, elements } = this.stripeCardRef.current;

          // );
          if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }
          const {
            error: stripeFetchUpdatesError,
          } = await elements.fetchUpdates();
          if (stripeFetchUpdatesError) {
            this.setState({ stripePaymentIntent: null });
            await new Promise(res => setTimeout(res, 1000));
            this.getStripePaymentIntent(true);
            throw new Error(error.message || 'Submit failed');
          }

          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  name: data.shippingDetails.name,
                  email: data.shippingDetails.email,
                  address: {
                    line1: data.shippingDetails.address.line1,
                    city: data.shippingDetails.address.city,
                    country: data.shippingDetails.address.country,
                    postal_code: (
                      data.shippingDetails.address.postalCode
                      || '00000'
                    ),
                  },
                },
              },
              // Make sure to change this to your payment completion page
              return_url: `${SERVER_URL
                }/${this.props.api.baseUrl
                }/orders/payment/stripe?orderId=${order._id
                }&requestEvent=redirect`,
            },
          });
          if (error) {
            this.setState({ stripePaymentIntent: null });
            await new Promise(res => setTimeout(res, 1000));
            this.getStripePaymentIntent(true);
            throw new Error(error.message || 'Submit failed');
          }
        }

        this.props.analytics.trigger.ORDER_SUBMIT({
          order,
          currency: systemCurrency,
        });
        if (
          order.payment.method === 'CARD'
          && order.payment.provider === 'PAYMOB_PIXEL'
          && order.payment.submethod !== 'APPLE_PAY'
        ) {
          try {
            this.setState({
              paymobOrderId: order?.paymob?.paymobId,
              error: null,
              submitting: true,
            });
            this.redirectToApplePayPage(order);
            return;
            // const event = new Event('payFromOutside');
            // event.eventName = 'payFromOutside';
            // event.initEvent('payFromOutside', true);
            // document.dispatchEvent(event);
            // clearTimeout(this.submitCardTimeout);
            // await new Promise((resolve, reject) => {
            //   this.submitCardTimeout = 
            // setTimeout(
            //     async () => {
            //       let finalError = null;
            //       try {
            //         await this.props.api.post(
            //           '/orders/payment/paymob',
            //           {},
            //           {
            //             merchant_order_id: order.payment.paymobId,
            //             timeout: true,
            //           }
            //         );
            //       } catch (error) {
            //         this.setState({ submitting: false });
            //         finalError = error;
            //       }
            //       try {
            //         await this.evaluateDebounced.now(
            //           this.state.items,
            //           values,
            //           this.props,
            //         );
            //       } catch (error) {
            //         this.setState({ submitting: false });
            //         finalError = error;
            //       }
            //       this.setState({
            //         submitting: false,
            //         // eslint-disable-next-line max-len
            //         error:  'Payment failed, please retry.',
            //       });
            //       if (finalError) {
            //         reject(finalError);
            //       } else {
            //         resolve();
            //       }
            //     },
            //     30000,
            //   );
            // });
            return;
          } catch (error) {
            this.debug('submit paymob error:', error);
          }
        } else if (
          order.payment.method === 'CARD'
          && order.payment.provider === 'PAYMOB_PIXEL'
          && order.payment.submethod === 'APPLE_PAY'
        ) {
          this.setState({
            paymobOrderId: data.paymob.paymobId,
            error: null,
            submitting: true,
          });

          this.redirectToApplePayPage(order);

          return;
        } else {
          const redirectUrl = (
            order?.payment?.redirectUrl
            || order?.payment?.paytabsPaymentPageUrl
            || order?.payment?.checkoutRedirectUrl
          );
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            this.props.store.removeData(KEY_CART_ITEMS);
            this.props.store.removeData(KEY_CART_OPEN);
            await new Promise(resolve => setTimeout(resolve, 300));
            // eslint-disable-next-line no-undef
            window.location.href = `/order/${order._id}?submitted=true`;
          }
        }
      }
      this.setState({
        error: null,
        submitting: false,
      });
    } catch (error) {
      clearTimeout(this.submitCardTimeout);
      this.debug('submit error:', error, error.response);
      let { message } = isString(error) ? { message: error } : error;
      if (error.response && error.response.status < 500) {
        // eslint-disable-next-line prefer-destructuring
        message = error.response.message;
        // 'Could not submit at this time, please try again later';
      }
      this.setState({
        error: message,
        submitting: false,
      });
    }
  }

  async redirectToApplePayPage() {
    const order = this.state.order;
    const redirectUrl = (
      order?.payment?.redirectUrl
      || order?.payment?.paytabsPaymentPageUrl
      || order?.payment?.checkoutRedirectUrl
    );
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      this.props.store.removeData(KEY_CART_ITEMS);
      this.props.store.removeData(KEY_CART_OPEN);
      await new Promise(resolve => setTimeout(resolve, 300));
      // eslint-disable-next-line no-undef
      window.location.href = `/order/${order._id}?submitted=true`;
    }
  }

  setError = error => this.setState({ error })

  setItemComment = (sku, comment) => {
    this.setCalculatedState(this.props, {
      items: this.state.items.map((item) => {
        if (item.sku === sku) {
          item = { ...item };
          if (comment) {
            item.comment = comment;
          } else {
            delete item.comment;
          }
        }
        return item;
      }),
    });
  }

  addToCart = (sku, price, meta, quantity = 1, replace = false) => {
    // console.log(meta);
    let productAlreadyInCart = false;
    const { currency: systemCurrency } = (
      this.props.store?.data
        ? Types.getSystemCountry(this.props.store.data.systemCountry)
        : {}
    );
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newItems = this.state.items.map((item) => {
      if (item.sku === sku) {
        productAlreadyInCart = true;
        const newQuantity = replace ? quantity : item.quantity + quantity;
        if (newQuantity > item.quantity) {
          this.props.analytics.trigger.CART_ADD({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity,
            replace,
            currency: systemCurrency,
          });
        } else if (newQuantity < item.quantity) {
          this.props.analytics.trigger.CART_REMOVE({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity,
            replace,
            currency: systemCurrency,
          });
        }
        return this.getCalculatedItem({
          ...item,
          meta: meta || item.meta,
          quantity: newQuantity,
        });
      }
      return item;
    });
    if (!productAlreadyInCart) {
      const item = this.getCalculatedItem({ sku, price, meta, quantity });
      this.props.analytics.trigger.CART_ADD({
        sku: item.sku,
        price: item.price,
        meta: item.meta,
        quantity: item.quantity,
        currency: systemCurrency,
      });
      // console.log('item:', item);
      newItems.push(item);
    }
    this.setCalculatedState(this.props, { items: newItems });
  }

  removeFromCart = (sku, quantity = 1) => {
    const { currency: systemCurrency } = (
      this.props.store?.data
        ? Types.getSystemCountry(this.props.store.data.systemCountry)
        : {}
    );
    const newItems = this.state.items.reduce(
      (agr, item) => {
        if (item.sku === sku) {
          const newQuantity = item.quantity - quantity;
          if (newQuantity > 0) {
            agr.push(this.getCalculatedItem({
              ...item,
              quantity: newQuantity,
            }));
          }
          this.props.analytics.trigger.CART_REMOVE({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity: Math.min(item.quantity, quantity),
            currency: systemCurrency,
          });
        } else {
          agr.push(item);
        }
        return agr;
      },
      [],
    );
    this.setCalculatedState(this.props, { items: newItems });
  }

  clearCart = () => {
    this.setCalculatedState(this.props, { items: [] });
  }

  getStripePaymentIntent = async (force = false) => {
    let { paymentCardProvider } = Types.getSystemCountry(
      this.props.store.data.systemCountry
    );
    if (this.props.store.data.paymentCardProvider) {
      paymentCardProvider = this.props.store.data.paymentCardProvider;
    }
    this.setState({ evaluting: true });
    if (!paymentCardProvider || paymentCardProvider !== 'STRIPE') {
      return;
    }
    if (
      !Object.keys(this.state.stripePaymentIntent || {}).length > 0
      || force
    ) {
      const stripePaymentIntent = await this.props.api.post(
        '/orders/payment/stripe',
        {},
        {
          requestEvent: 'createIntent',
        },
      );
      if (stripePaymentIntent) {
        if (force) {
          setTimeout(() => this.setState({ error: null }), 10000)
        }
        this.setState({ stripePaymentIntent, evaluting: false });
      }
    }
  }

  toggleShowCart = (force = null) => {
    const isOpen = !isBoolean(force)
      ? !this.state.isOpen
      : force;
    if (this.state.isOpen !== isOpen) {
      this.setState({ isOpen });
      this.props.store.setData(KEY_CART_OPEN, !!isOpen);
    }
  }

  render() {
    return (
      this.state.loading
        ? null
        : super.render()
    );
  }
}

export function useCart() {
  return useContext(Context);
}

const defaultShippingCountryOptions = Types.COUNTRIES.map(
  ({ name, iso3a2 }) => ({
    value: iso3a2,
    label: name,
  })
);

export function useShippingCountryOptions() {
  const { countries = [] } = useCart();
  if (countries && countries.length) {
    const shippingCountryOptions = (
      countries.reduce(
        (agr, country) => {
          if (Types.COUNTRIES_MAP[country]) {
            agr.push({
              value: country,
              label: Types.COUNTRIES_MAP[country].name,
            });
          }
          return agr;
        },
        [],
      )
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    );
    return shippingCountryOptions;
  }
  return defaultShippingCountryOptions;
}

export function useShippingOptions() {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();
  const {
    cartUnavailable,
    cartPaymentFree,
    SHIPPING_METHOD_LABELS_MAP,
    SHIPPING_METHOD_DURATIONS_MAP
  } = useDictionary();
  const { shipping = {} } = useCart();

  const shippingOptions = useMemo(
    () => Types.SHIPPING_METHOD.reduce(
      (agr, methodId) => {
        const method = shipping[methodId];
        if (method) {
          const disabled = method.available === false;
          const description = [];
          if (disabled) {
            description.push(method.message || cartUnavailable);
          } else {
            if (!method.free && method.price > 0) {
              description.push(getPriceWithCurrency(
                method.price,
                storeData,
                currencyObject,
              ));
            } else {
              description.push(cartPaymentFree);
            }
            const durationLabel = SHIPPING_METHOD_DURATIONS_MAP[methodId];
            if (durationLabel) {
              description.push(durationLabel);
            }
          }
          agr.push({
            disabled,
            label: SHIPPING_METHOD_LABELS_MAP[methodId],
            description,
            value: methodId,
          });
        }
        return agr;
      },
      [],
    ),
    [
      shipping,
      storeData,
      currencyObject,
      cartPaymentFree,
      cartUnavailable,
      SHIPPING_METHOD_LABELS_MAP,
      SHIPPING_METHOD_DURATIONS_MAP,
    ],
  );

  return shippingOptions;
}

export function useShippingScheduledOptions() {
  const {
    cartScheduledShippingNow,
    cartScheduledShippingLater,
  } = useDictionary();
  return [{
    label: cartScheduledShippingNow,
    value: false,
  }, {
    label: cartScheduledShippingLater,
    value: true,
  }];
}

export function usePaymentOptions() {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();
  const {
    cartUnavailable,
    cartPaymentFree,
    PAYMENT_METHOD_LABELS_MAP,
    PAYMENT_SUBMETHOD_LABELS_MAP,
    cartPaymentTabbyMessageNotAvailable,
    cartPaymentTabbyMessageAmountTooHigh,
    cartPaymentTabbyMessageAmountTooLow,
    cartPaymentTabbyMessagePromo,
  } = useDictionary();
  const cartPaymentTabbyMessages = useMemo(
    () => ({
      cartPaymentTabbyMessageNotAvailable,
      cartPaymentTabbyMessageAmountTooHigh,
      cartPaymentTabbyMessageAmountTooLow,
      cartPaymentTabbyMessagePromo,
    }),
    [
      cartPaymentTabbyMessageNotAvailable,
      cartPaymentTabbyMessageAmountTooHigh,
      cartPaymentTabbyMessageAmountTooLow,
      cartPaymentTabbyMessagePromo,
    ],
  );
  const { data: { systemCountry } } = useStore();
  const { payment = {} } = useCart();

  const paymentOptions = useMemo(
    () => {
      let { paymentCardProvider } = Types.getSystemCountry(systemCountry);
      if (storeData.paymentCardProvider) {
        paymentCardProvider = storeData.paymentCardProvider;
      }

      return Types.PAYMENT_METHOD_LIST.reduce(
        (agr, method) => {
          const methodId = method.id;
          const paymentMethod = payment[method.id];
          if (
            paymentMethod
            && (
              !method.checkBrowserSupport
              || method.checkBrowserSupport(storeData)
            )
            && (
              !method.providers
              || method.providers.includes(paymentCardProvider)
            )
          ) {
            const disabled = paymentMethod.available === false;
            const description = [];
            if (disabled) {
              description.push(paymentMethod.message || cartUnavailable);
            } else if (!paymentMethod.free && paymentMethod.price > 0) {
              description.push(getPriceWithCurrency(
                paymentMethod.price,
                storeData,
                currencyObject,
              ));
            } else if (
              paymentMethod?.messageKey
              && cartPaymentTabbyMessages[paymentMethod?.messageKey]
            ) {
              description.push(Types.replaceVars(
                cartPaymentTabbyMessages[paymentMethod?.messageKey],
                { amount: paymentMethod?.chargedNow },
              ));
            } else if (paymentMethod?.message) {
              description.push(paymentMethod.message);
            } else {
              description.push(cartPaymentFree);
            }
            agr.push({
              disabled,
              label: (
                methodId === 'CARD' && paymentCardProvider === 'PAYMOB'
                  ? 'CARD / APPLE PAY'
                  : PAYMENT_METHOD_LABELS_MAP[methodId]
              ),
              description,
              chargedNow: paymentMethod.chargedNow,
              value: methodId,
            });
          }
          if (method.METHODS_LIST && method.METHODS_LIST.length) {
            method.METHODS_LIST.forEach((submethod) => {
              const submethodId = `${method.id}.${submethod.id}`;
              const paymentSubmethod = payment[submethodId];
              if (
                paymentSubmethod
                && paymentSubmethod.available
                && (
                  !submethod.checkBrowserSupport
                  || submethod.checkBrowserSupport(storeData)
                )
                && (
                  !submethod.providers
                  || submethod.providers.includes(paymentCardProvider)
                )
              ) {
                const disabled = paymentSubmethod.available === false;
                const description = [];
                if (disabled) {
                  description.push(
                    paymentSubmethod.message || cartUnavailable
                  );
                } else if (
                  !paymentSubmethod.free
                  && paymentSubmethod.price > 0
                ) {
                  description.push(getPriceWithCurrency(
                    paymentSubmethod.price,
                    storeData,
                    currencyObject,
                  ));
                } else if (
                  paymentSubmethod?.messageKey
                  && cartPaymentTabbyMessages[paymentSubmethod?.messageKey]
                ) {
                  description.push(Types.replaceVars(
                    cartPaymentTabbyMessages[paymentSubmethod?.messageKey],
                    { amount: paymentSubmethod?.chargedNow },
                  ));
                } else if (paymentSubmethod?.message) {
                  description.push(paymentSubmethod.message);
                } else {
                  description.push(cartPaymentFree);
                }
                agr.push({
                  disabled,
                  label: PAYMENT_SUBMETHOD_LABELS_MAP[method.id][submethod.id],
                  description,
                  chargedNow: paymentSubmethod.chargedNow,
                  value: submethodId,
                });
              }
            });
          }
          return agr;
        },
        [],
      );
    },
    [
      payment,
      storeData,
      cartPaymentFree,
      systemCountry,
      currencyObject,
      cartUnavailable,
      PAYMENT_METHOD_LABELS_MAP,
      PAYMENT_SUBMETHOD_LABELS_MAP,
      cartPaymentTabbyMessages,
    ],
  );

  return paymentOptions;
}

// eslint-disable-next-line no-unused-vars
export function useShouldHideShippingOptions(shippingOptions = []) {
  const { isAbstract, posOrderId } = useCart();
  return !!(isAbstract || posOrderId);
}

export function useShouldHidePaymentOptions(paymentOptions = []) {
  const { isAbstract } = useCart();
  return (
    isAbstract
    && paymentOptions?.length === 1
    && paymentOptions?.[0]?.value === 'CARD'
  );
}
